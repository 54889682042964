.news-inner {
    .bg-image {
        width: 100%;
        height: 20rem;
        background-image: linear-gradient(to bottom, #0000 2%, #0c0c0c ), var(--img);
        background-size: cover;
        background-position: center;
    }

    .contents {
        .meta {
            margin-top: -7rem;
            margin-bottom: 5rem;
            padding: 0 2rem;
        }
        .body {
            white-space: pre-wrap;
            font-size: 1.5rem;
            font-weight: 300;
            line-height: 1.5;
            padding: 0 4rem;

            @media (max-width: 800px) {
                padding: 0 2rem;
            }
        }
    }
}
