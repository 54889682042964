.navbar {
    margin-top: 1rem;
    .nav {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        & > a {
            text-decoration: none;
            color: #fff;
            font-size: 1.2rem;
            font-weight: 400;
            padding: 1rem 0;
            width: 100%;
            text-align: center;

            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #fff1;
            }

            &:active {
                background-color: #fff3;
            }
        }
    }
}
