.news-item {
    .image {
        width: 280px;
        height: 180px;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;

        @media (max-width: 800px) {
            width: 100%;
            height: 100%;
        }
    }

    .text {
        margin-left: 2rem;
        display: flex;
        flex-direction: column;

        @media (max-width: 800px) {
            margin-left: 0;
            margin-top: 1rem;
        }

        & > * {
            margin: 0;
        }

        .title {
            font-size: 1.5rem;
            font-weight: 700;
            margin-top: 0.5rem;
            margin-bottom: 2rem;
            color: #ffffff;
            text-decoration: none;

            @media (max-width: 800px) {
                font-size: 1.2rem;
            }
        }

        .date {
            font-style: italic;
        }
    }

    &.list {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;

        @media (max-width: 800px) {
            flex-direction: column;
        }
    }

}
