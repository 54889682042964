.about-inner {
    .contents {
        padding: 0 4rem;

        @media (max-width: 800px) {
            padding: 0 2rem;
        }
        .body {
            white-space: pre-wrap;
            font-size: 1.5rem;
            font-weight: 300;
            line-height: 1.5;

        }
    }
}
